import { Component, OnInit } from '@angular/core';
import {CartService} from '../../services/cart/cart.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
})
export class CartComponent implements OnInit {
  pcart: any[] = [];
  total = 0;
  showPayment = false;
  constructor(private router : Router ,private cartService: CartService) {
    this.pcart = this.cartService.fetchCartP();
  }

  removeFromCartP(index: number) {
    this.cartService.deleteProductFromCartP(index);
    this.calculateTotalP();
    this.cartService.fetchCartP();
  }

  navigateToPayment() {
    // this.router.navigateByUrl('/payment');
    this.showPayment = true;
  }


  calculateTotalP() {
    this.total = this.pcart.reduce((acc, item) => item.prix, 0);
    localStorage.setItem(
        'total',
        this.pcart.reduce((acc, item) => item.prix.slice(0,5), 0)
    );
  }


  ngOnInit(): void {
    this.calculateTotalP();
    this.pcart = this.cartService.fetchCartP();
  }
}