import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CartService {
  private pcart: any[] = [];
  private cartPSubject = new BehaviorSubject<any[]>(this.getCartFromLocalStorage());
  cartP$ = this.cartPSubject.asObservable();  // Observable for cart changes


  constructor() {
    const pstoredCart = localStorage.getItem('cartP');
    if (pstoredCart) {
      this.pcart = JSON.parse(pstoredCart);
    }

  }

  private psaveCart() {
    localStorage.setItem('cartP', JSON.stringify(this.pcart));
    this.cartPSubject.next(this.pcart);  // Emit the latest cart value
  }



  fetchCartP() {
    return this.pcart;
  }



  deleteProductFromCartP(position: number) {
    this.pcart.splice(position, 1);
    this.psaveCart();
  }



  addToCartP(item: any) {
    this.pcart.push(item);
    this.psaveCart();
  }

  private getCartFromLocalStorage(): any[] {
    const cartData = localStorage.getItem('cartP');
    return cartData ? JSON.parse(cartData) : [];
  }


}