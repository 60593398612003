import { Component } from '@angular/core';

@Component({
  selector: 'app-payment-hero',
  templateUrl: './payment-hero.component.html',
  styleUrls: ['./payment-hero.component.scss']
})
export class PaymentHeroComponent {

}
