import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import {Abonnement} from '../../models/Abonnement';

@Injectable({
  providedIn: 'root'
})
export class AbonnementService {

  // URL de base pour l'API d'abonnement
  private apiUrl = environment.apiRequest + 'administration/api/abonnement';



  

  // Injection de HttpClient pour faire les requêtes HTTP
  constructor(private http: HttpClient) { }

  // Récupérer tous les abonnements
  getAbonnements(): Observable<Abonnement[]> {
    return this.http.get<Abonnement[]>(`${this.apiUrl}/abonnements`);
  }

  // Récupérer un abonnement par ID
  getAbonnementById(id: string): Observable<Abonnement> {
    return this.http.get<Abonnement>(`${this.apiUrl}/${id}`);
  }

  // Créer un nouvel abonnement
  createAbonnement(abonnement: Abonnement): Observable<Abonnement> {
    return this.http.post<Abonnement>(this.apiUrl, abonnement);
  }

  // Mettre à jour un abonnement existant
  updateAbonnement(id: string, abonnement: Abonnement): Observable<Abonnement> {
    return this.http.put<Abonnement>(`${this.apiUrl}/${id}`, abonnement);
  }

  // Supprimer un abonnement
  deleteAbonnement(id: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/${id}`);
  }
}