import { Component, ElementRef, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Stripe, StripeCardElement } from '@stripe/stripe-js';
import {PaymentService} from '../services/payment/payment.service';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute} from '@angular/router';
import {RentalRequestService} from '../services/RentalRequest/rental-request.service';
import {RentalRequest} from '../models/rentalRequest';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
})
export class PaymentComponent implements OnInit, AfterViewInit {
  @ViewChild('cardElement') cardElement!: ElementRef;
  payform: FormGroup;
  isClicked = false;

  email: string | null = null; // Email récupéré de l'URL
  rentalRequest: RentalRequest; // ID de la demande de location

  private stripe: Stripe | null = null;
  private card: StripeCardElement | null = null;

  total: any = localStorage.getItem('total');
  userId: any = sessionStorage.getItem('user-key');
  userObject = JSON.parse(this.userId);

  // Accédez à l'ID utilisateur
  id = this?.userObject?.id;

  fetchId : any = this?.userId?.id;
  pcart: any = localStorage.getItem('cartP');
  // cart = JSON.parse(this.pcart);
  constructor(private route: ActivatedRoute, private toastr : ToastrService, private formBuilder: FormBuilder,
              private ps: PaymentService, private rentalRequestService: RentalRequestService) {}

  ngOnInit(): void {
    // Extraire les paramètres de l'URL, y compris l'e-mail
    this.route.queryParams.subscribe(params => {
      this.email = params.email; // Récupère l'e-mail dans l'URL
      if (this.email) {
        // Appeler le backend pour récupérer l'ID de la demande de location à partir de l'email
        this.rentalRequestService.getRentalRequestByEmail(this.email).subscribe(response => {
          this.rentalRequest = response; // Assurez-vous que le backend renvoie l'ID de la demande de location
          console.log('Rental Request ID:', this.rentalRequest);
        });
      }
    });
    // Création du formulaire avec FormBuilder
    this.payform = this.formBuilder.group({
      owner: [''], // Champ de formulaire obligatoire
    });

    const storedCart = localStorage.getItem('cartP');
    this.pcart = storedCart ? JSON.parse(storedCart) : [];

  }

  get owner() {
    return this.payform.get('owner');
  }

  async ngAfterViewInit() {
    try {
      this.stripe = await this.ps.getStripe(); // Méthode pour récupérer Stripe depuis votre service

      if (this.stripe) {
        const elements = this.stripe.elements();
        this.card = elements.create('card');
        this.card.mount(this.cardElement.nativeElement);
      } else {
        console.error('Stripe is not initialized.');
      }
    } catch (error) {
      console.error('Error loading Stripe:', error);
    }
  }

  async handlePayment() {
    if (!this.stripe || !this.card) {
      console.error('Stripe or Card element is not initialized.');
      return;
    }

    try {
      // Créer le PaymentMethod à partir de l'élément de carte
      const { paymentMethod, error } = await this.stripe.createPaymentMethod({
        type: 'card',
        card: this.card,
      });

      if (error) {
        console.error('Erreur lors de la création du PaymentMethod:', error);
        return;
      }

      console.log('Payment Method created:', paymentMethod);

      // Récupérer le client_secret du serveur
      const amountInCents = Math.round(this.total * 100);
      const abonnementId = this.pcart.length > 0 ? this.pcart[0] : null;

      if (!abonnementId) {
        console.error('Abonnement ID is missing.');
        return;
      }

      const response = await this.ps.createPaymentIntent({
        amount: amountInCents,
        currency: 'usd',
        payment_method_types: ['card'],

      }).toPromise();

      console.log('PaymentIntent response:', response); // Log the entire response
      const clientSecret = response?.paymentMethodId;
      console.log('Client Secret ::: '+JSON.stringify(clientSecret));
      if (!clientSecret) {
        console.error('client_secret is missing from the response:', response);
        return;
      }

      const paymentMethodId = paymentMethod.id; // Utilisez l'ID du PaymentMethod créé
      // Confirmer le paiement avec Stripe
      const confirmResponse = await this.stripe.confirmCardPayment(clientSecret, {
        payment_method: paymentMethodId,
      });

      console.log('Payment confirmation response:', confirmResponse);

      if (confirmResponse.error) {
        console.error('Erreur lors de la confirmation du paiement:', confirmResponse.error);
        // Gérer l'erreur, par exemple afficher un message d'erreur à l'utilisateur
      } else {
        console.log('Paiement confirmé avec succès:', confirmResponse.paymentIntent);
        const payment = {
          amount: amountInCents,
          currency: 'usd',
          paymentMethodId: paymentMethod.id,
          owner: this.owner?.value,
          abonnement: abonnementId, // Suppression de JSON.parse
          rentalRequest: this.rentalRequest
        };

        // alert(this.pcart[0].id);
        this.ps.savePayment(payment).subscribe(() => {
          this.isClicked = true;
          this.toastr.success('Payment Successful', 'Success');
          // Trigger payment success event
          this.ps.triggerPaymentSuccess();  // Add this line
        });
        localStorage.removeItem('total');
        localStorage.removeItem('cartP');
      }
    } catch (error) {
      console.error('Erreur lors du traitement du paiement:', error);
    }
  }

}