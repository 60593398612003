import {Component, OnInit} from '@angular/core';
import {Abonnement} from '../models/Abonnement';
import {AbonnementService} from '../services/abonnement/abonnement.service';
import {CartService} from '../services/cart/cart.service';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-plans',
  templateUrl: './plans.component.html',
  styleUrls: ['./plans.component.scss']
})
export class PlansComponent implements OnInit{

  abonnements: Abonnement[] = [];  // Stockage des abonnements récupérés
  isLoading = true;
  item : any = {};
  cartP = localStorage.getItem('pcart');
  constructor(private toastr: ToastrService, private cartService : CartService, private abonnementService: AbonnementService) { }

  ngOnInit(): void {
    // Récupération des abonnements depuis le backend
    this.abonnementService.getAbonnements().subscribe({
      next: (data: Abonnement[]) => {
        this.abonnements = data;
        this.isLoading = false;
      },
      error: (error) => {
        console.error('Erreur lors de la récupération des abonnements:', error);
        this.isLoading = false;
      }
    });

  }

  storeP(row: any) {
    // Retrieve cartP from localStorage and parse it
    const cartData = localStorage.getItem('cartP');
    const cartP = cartData ? JSON.parse(cartData) : [];

    // Check the length of the cart
    if (cartP.length > 0) {
      this.toastr.info('A Plan is already added to your cart, cannot add more than one plan', 'SHOPPING CART');
    } else {
      // Add the new item to the cart
      cartP.push(row);

      // Update localStorage with the new cartP
      localStorage.setItem('cartP', JSON.stringify(cartP));

      // Call your cart service to manage the cart on the backend (if necessary)
      this.cartService.addToCartP(row);

      // Show success message
      this.toastr.success('A Plan is added to your cart', 'SHOPPING CART');

      // Reset the item
      this.item = {};
    }
  }

}