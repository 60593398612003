import { Injectable } from '@angular/core';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  private stripePromise: Promise<Stripe | null> = loadStripe('pk_test_51NFfUfAH3eU6rKRWTJ5HdRRrtkcaXlfDPbjxDFd6oXFafbzVanH4ffh2TYUYAABBwSpmYQcC8YcCm4k4s3CL5dCT00c1pkv4pK');
  private paymentSuccessSubject = new Subject<void>();
  // Observable to listen for payment success
  paymentSuccess$ = this.paymentSuccessSubject.asObservable();

  constructor(private http: HttpClient) {
  }

  async getStripe(): Promise<Stripe | null> {
    return this.stripePromise;
  }

  createPaymentIntent(intentRequest: any): Observable<any> {
    const url = `${environment.apiRequest}administration/payments/initialize`;
    // Ensure content-type header is set if needed
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<any>(url, intentRequest, {headers});
  }

  confirmPayment(paymentIntentId: string, paymentMethodId: string): Observable<any> {
    const url = `${environment.apiRequest}administration/payments/confirm`;
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    const params = new HttpParams()
        .set('paymentIntentId', paymentIntentId)
        .set('paymentMethodId', paymentMethodId);
    return this.http.post<any>(url, null, {headers, params});
  }

  savePayment(data: any): Observable<any> {
    const url = `${environment.apiRequest}administration/payments/save`;
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<any>(url, data, {headers});
  }

  retrieveAll(): Observable<any[]> {
    return this.http.get<any[]>(`${environment.apiRequest}administration/payments/fetchAll`);
  }

  retrieveById(id: any): Observable<any[]> {
    return this.http.get<any[]>(`${environment.apiRequest}administration/payments/fetchOne/${id}`);
  }

  triggerPaymentSuccess() {
    this.paymentSuccessSubject.next();
  }
}